
import { defineComponent, ref } from 'vue'
import TemplateFormDetails from '@/components/modals/shared/template/TemplateFormDetails.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmFormLine,
    TemplateFormDetails,
  },
  setup() {
    const currencyList = [
      'US Dollar (USD)',
      'Euro (EUR)',
    ]
    const currency = ref(currencyList[0])

    return {
      currency,
      currencyList,
    }
  },
})
