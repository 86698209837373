import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_template_form_details = _resolveComponent("template-form-details")!

  return (_openBlock(), _createBlock(_component_template_form_details, {
    "init-name": "Simple pipeline",
    "init-description": "Visualize campaign progress and milestones.",
    "description-hint": "Describe shortly what this board will be used for."
  }, {
    "before-description": _withCtx(() => [
      _createVNode(_component_tm_form_line, {
        label: "Currency",
        class: "mb-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.currency,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currency) = $event)),
            type: "select",
            options: _ctx.currencyList,
            clearable: false
          }, null, 8, ["modelValue", "options"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}